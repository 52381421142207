<!-- Button trigger modal -->
<template>
  <div>
    <div
      style="padding-right: 0 !important"
      class="modal fade"
      :class="[route === 'Sure' ? 'top-push' : '']"
      id="sub"
      tabindex="-1"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div
        style="margin-bottom: 6rem;"
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="modal-header" style="border-bottom: 0.5px solid #e2e8f0">
            <!-- <h5 class="modal-title" id="sub">Modal title</h5> -->
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style="font-weight: normal">X</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <img src="../assets/images/handshake-subscription.svg" alt="" />
            </div>
            <div class="top_header">
              <h4>Shelta Value Added Services</h4>
              <p>
                For just ₦10,000, you can gain access to a whole new world of
                tailored benefits and enhanced offerings with Shelta.
              </p>
            </div>
            <div>
              <p class="benefits-text">Benefits</p>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/images/check-circle-blue.svg"
                          alt=""/></span
                      >Rent Finance - SWITCH
                    </h6>
                    <p>
                      Enjoy flexibility as Shelta enables you switch your annual payment to start paying rent monthly, quarterly and biannually to us while your landlord gets full annual rent paid to them by Shelta.
Eligibility requirement applies.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/images/check-circle-blue.svg"
                          alt=""/></span
                      >Zero Agency Fee and Zero Legal Fees:
                    </h6>
                    <p>
                      Enjoy Zero Agency and Zero Legal fees as Shelta does not require you to pay agency fee or legal/agreement fee when moving into a new apartment managed by Shelta anywhere in Nigeria.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/images/check-circle-blue.svg"
                          alt=""/></span
                      >SWAP:
                    </h6>
                    <p>
                      As a Shelta user, you can Swap your apartment for another within and outside the Shelta ecosystem - T&C applies!
                    </p>
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/images/check-circle-blue.svg"
                          alt=""/></span
                      >Gap Financing
                    </h6>
                    <p>
                      Shelta helps you complete your rent when the need arises. This service is made possible through Shelta SAFE.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/images/check-circle-blue.svg"
                          alt=""/></span
                      >Property Financing
                    </h6>
                    <p>
                      We can help finance your property acquisition project
                      including mortgage facilitation.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 pl-0">
                  <div class="benefit-wraps">
                    <h6>
                      <span
                        ><img
                          src="../assets/images/check-circle-blue.svg"
                          alt=""/></span
                      >You have a Personal Caretaker
                    </h6>
                    <p>
                      Tenant and landlord advisory service on all property
                      related issues - A personal caretaker service.
                    </p>
                  </div>
                </div>
              </div>

              <div class="note">
                <h6>Note</h6>
                <ul>
                  <li>
                    This subscription is non-refundable.
                  </li>
                  <li>
                    Please note that subscription alone does not guarantee eligibility for rent financing. T&C applies.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer modal-button main-footer-button">
            <!-- <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button> -->
            <paystack
              type="button"
              class="btn continue"
              :disabled="subscribed === 'YES'"
              :amount="amount * 100"
              :email="email"
              :paystackkey="PUBLIC_KEY"
              :reference="reference"
              :callback="processPayment"
              :close="close"
              :channels="channels"
              @click="$refs.paystack.click()"
            >
              Become a Shelta User
              <i class="fa fa-arrow-right"></i>
            </paystack>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for benefits end -->

    <!-- Modal for amount confirmation begins -->
    <div
      tabindex="-1"
      class="modal fade"
      id="paymentConfirm"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="modal-header">
            <h5 class="modal-title confirmation-header">
              Subscription Confirmation
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" style="font-weight: normal">X</span>
            </button>
          </div>
          <div class="modal-body confirmation-body">
            <p>Great choice!</p>
            <p>
              You are now on your way to becoming a valued Shelta member. A
              one-time payment of <span>₦10,000</span> will be deducted from your
              account to activate your Shelta membership.
            </p>
          </div>
          <div class="modal-footer modal-button">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              class="btn continue"
              @click="closeConfirmation"
              data-toggle="modal"
              data-target="#paymentOption"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for amount confirmation ends -->

    <!-- Modal for payment starts -->
    <div
      tabindex="-1"
      class="modal fade"
      id="paymentOption"
      role="dialog"
      aria-labelledby="sub"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="margin-bottom: 30px">
          <div class="modal-header">
            <h5 class="modal-title confirmation-header">
              Payment Method
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                @click="paymentType = ''"
                aria-hidden="true"
                style="font-weight: normal"
                >X</span
              >
            </button>
          </div>
          <div class="modal-body">
            <div class="options">
              <button
                class="btn d-flex center"
                type="button"
                @click="
                  $refs.safe.click();
                  scrollView;
                "
              >
                <input
                  ref="safe"
                  v-model="paymentType"
                  value="safe"
                  style="width: 20px; height: 20px;"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <img
                  style="width: 24px; margin-left: 10px;"
                  src="../assets/images/blue-safe.svg"
                  alt="safe"
                />
                <span class="pl-2">SAFE</span>
              </button>
              <paystack
                :disabled="subscribed === 'YES'"
                :channels="channels"
                :amount="amount * 100"
                :email="email"
                :paystackkey="PUBLIC_KEY"
                :reference="reference"
                :callback="processPayment"
                :close="close"
                class="btn"
                type="button"
                @click="$refs.paystack.click()"
              >
                <input
                  v-model="paymentType"
                  value="paystack"
                  ref="paystack"
                  style="width: 20px; height: 20px;"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <img
                  style="width: 24px; margin-left: 10px;"
                  src="../assets/images/paystack-blue.svg"
                  alt="paystack"
                /><span class="pl-2">Paystack</span>
              </paystack>
              <button
                class="btn"
                type="button"
                @click="
                  $refs.bank.click();
                  payFromBank();
                "
              >
                <input
                  v-model="paymentType"
                  value="bank"
                  ref="bank"
                  style="width: 20px; height: 20px;"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                /><img
                  style="margin-left: 10px;"
                  src="../assets/images/bank-transfer.svg"
                  alt="transfer"
                /><span class="pl-2">Bank Transfer</span>
              </button>
              <button
                class="btn d-none"
                type="button"
                @click="$refs.naira.click()"
              >
                <input
                  v-model="paymentType"
                  value="naira"
                  ref="naira"
                  style="width: 20px; height: 20px;"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                /><img
                  style="width: 24px; margin-left: 10px;"
                  src="../assets/images/enaira-green.svg"
                  alt="enaira"
                /><span class="pl-2">E-Naira</span>
              </button>
            </div>
          </div>
          <div class="modal-footer modal-button">
            <button
              @click="paymentType = ''"
              type="button"
              class="btn cancel"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              ref="continue"
              v-if="paymentType === 'safe'"
              type="button"
              class="btn continue"
              @click="subscribeWithSafe"
            >
              Continue
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="loader1"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for payment ends -->

    <!-- Modal for bank statment upload begins -->
    <div
      class="modal fade"
      id="showpayment"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showpayment"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header payment_header_holder">
            <img
              v-if="headerText2"
              @click="switchView(0)"
              class="back__arrow"
              src="../assets/images/arrow2-white.svg"
              alt=""
            />
            <img
              v-if="headerText3"
              @click="switchView(1)"
              class="back__arrow"
              src="../assets/images/arrow2-white.svg"
              alt=""
            />
            <h5
              v-if="headerText1"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Pay My Rent
            </h5>
            <h5
              v-if="headerText2"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Proof of payment upload.
            </h5>
            <h5
              v-if="headerText3"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Call Shelta.
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="close_times">&times;</span>
            </button>
          </div>
          <div id="onee" class="modal-body">
            <div class="">
              <div class="dot-holder">
                <span class="step active"></span>
                <span class="step"></span>
                <span class="step"></span>
                <!-- <span class="step"></span> -->
              </div>
              <div class="double_arrow_holder">
                <img src="../assets/images/double-arrow-blue.svg" alt="back" />
                <p>Bank Transfer</p>
              </div>
              <div class="form-outter">
                <div class="first-step">
                  <div class="number__holder">
                    <p class="span-count">1</p>
                    <p>
                      Transfer the amount due to the acount number below
                    </p>
                  </div>

                  <div class="sheltaAccount__details">
                    <div>
                      <p>Amount:</p>
                      <p>
                        &#8358;10,000.
                      </p>
                    </div>
                    <div>
                      <p>Account Name:</p>
                      <p>Shelta Panacea Limited</p>
                    </div>
                    <div>
                      <p>Account Number :</p>
                      <p>0708490113</p>
                    </div>
                    <div>
                      <p>Bank :</p>
                      <p>Guarantee Trust Bank</p>
                    </div>
                  </div>
                  <div class="number__holder second__number">
                    <p class="span-count">2</p>
                    <p>
                      After the transfer is completed, please keep your payment
                      receipt by screenshot or photo and upload it in the next
                      step.
                    </p>
                  </div>

                  <div class="desclaimer__text">
                    <p>
                      Kindly note that your rent starts running 48 hours after
                      your payment is approved
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-foot">
              <button
                @click="switchView(1)"
                type="button"
                class="btn next-btn btn-block"
              >
                Next
              </button>
            </div>
          </div>

          <div id="twoo" class="modal-body">
            <div class="container-fluid">
              <div class="dot-holder">
                <span class="step"></span>
                <span class="step active"></span>
                <span class="step"></span>
              </div>
              <div class="form-outter">
                <div class="second-step">
                  <p class="info__text">
                    Please upload the payment receipt so that we can find your
                    payment.
                  </p>
                  <!-- <div class="dateOf__payment">
                    <label for="">Date of payment <span>*</span></label>
                    <input
                      class="form-control"
                      name="date"
                      type="date"
                      placeholder="Select date of payment"
                    />
                  </div> -->

                  <div class="upload__file">
                    <div class="dropzone">
                      <input
                        @change="selectFile"
                        class="input-file"
                        ref="file"
                        type="file"
                      />
                      <img src="../assets/images/file-upload.svg" alt="" />
                      <img
                        v-if="fileselected"
                        class="actual_image"
                        :src="filePath"
                        alt="reciept"
                      />
                      <p
                        v-if="!uploading && !fileselected"
                        class="call-to-action"
                      >
                        Drag and drop or
                        <span class="browse__span">browse</span> your files
                      </p>
                      <p
                        v-if="uploading"
                        class="call-to-action"
                        style="color: green;"
                      >
                        Uploding your reciept...
                      </p>
                      <!-- <p v-if="uploading" class="progress-bar">
                        <progress
                          class="progress is-primary"
                          :value="progress"
                          max="100"
                          >{{ progress }} %</progress
                        >
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p v-if="uploading" class="call-to-action" style="color: green;">
              Uploding your reciept...
            </p>
            <div class="modal-foot">
              <!-- switchView(2) -->
              <button
                @click="sendFile"
                type="button"
                class="btn next-btn btn-block"
              >
                SUBMIT
                <i
                  class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                  v-if="loading"
                ></i>
              </button>
            </div>
          </div>

          <div id="threee" class="modal-body">
            <div class="container-fluid">
              <div class="dot-holder">
                <span class="step"></span>
                <span class="step"></span>
                <span class="step active"></span>
              </div>
              <div class="form-outter">
                <div class="third-step">
                  <img src="../assets/images/rent-call.svg" alt="" />
                  <p>
                    Submited successfully ! We will confirm your payment and
                    update your status within 48 hours. For enquiries please
                    call our customer service agent on :
                  </p>
                </div>
              </div>
            </div>
            <div class="modal-foot">
              <button type="button" class="btn call-btn">
                <a href="tel:2348061796909">
                  <img src="../assets/images/phone-conect.svg" alt="" />
                  08061796909
                </a>
              </button>
            </div>
          </div>

          <!-- <div class="modal-footer modal-foot">
            
            <button type="button" class="btn next-btn btn-block">Next</button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Modal for bank statment upload ends -->
  </div>
</template>

<script>
import { Service } from "../store/service";
import paystack from "vue-paystack";
import PageLoader from "./PageLoader.vue";
import { staticdata } from "../../src/data";
const Api = new Service();
import $ from "jquery";
export default {
  name: "SubscriptionModal",
  components: {
    paystack,
    PageLoader
    // FooterMain,
  },
  data() {
    return {
      hideDiv: false,
      loading: false,
      amount: 10000,
      full_name: "",
      email: "",
      PUBLIC_KEY: staticdata.paystackkeylive,
      user: "",
      reference: "",
      subscribed: "",
      uploading: false,
      uploadFile: "",
      fileselected: false,
      filePath: "",
      progress: 0,
      file: "",
      headerText1: true,
      headerText2: false,
      headerText3: false,
      loader1: false,
      paymentType: "",
      channels: ["card", "bank", "ussd", "qr", "bank_transfer"],
      route: ""
    };
  },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  created() {
    this.$root.$refs.D = this;
  },
  mounted() {
    if (localStorage.getItem("shelta")) {
      this.getSubscription();
      this.user = JSON.parse(localStorage.getItem("shelta-user"));
      this.email = this.user.email;
      this.full_name = this.user.firstname + this.user.lastname;
    }
    this.route = this.$route.name;
  },
  methods: {
    openSubModal() {
      $("#sub")
        .addClass("fade")
        .modal("show");
    },
    closeConfirmation() {
      $("#paymentConfirm")
        .removeClass("fade")
        .modal("hide");
    },
    processPayment(res) {
      // console.log("clikedd", res);
      const ref = res.reference;
      if (res.status === "success") {
        Api.postRequest(`makesubscription`, {
          amount: 10000,
          referencecode: ref
        })
          .then(res => {
            // console.log("Subscription response", res);
            if (res.data.success === "success") {
              this.getSubscription();
              $("#sub")
                .removeClass("fade")
                .modal("hide");
              return this.$store.commit(
                "setApiSuccess",
                "Subscription successful"
              );
            }
          })
          .catch(err => {
            console.log("err", err);
            // return this.$store.commit("setApiFailed", "Error occured");
          });
      }
    },
    // Paystack close function or cancel payment
    close: () => {
      // console.log("You closed checkout page");
    },
    async getSubscription() {
      this.hideDiv = true;
      try {
        await Api.getRequest(`checkifuserhassubscribed/${this.user.id}`)
          .then(res => {
            this.hideDiv = false;
            // console.log("Subscribed check", res);
            if (res.data.success === "success") {
              Api.userProfile();
              this.subscribed = res.data.paid;
            }
            // return this.$store.commit("setApiSuccess", "Sub success");
          })
          .catch(err => {
            console.log("err", err);
            this.hideDiv = false;
            return this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("closed");
      }
    },
    payFromSafe() {
      if (this.subscribed === "YES") {
        return this.$store.commit("setApiFailed", "You already scubscribed");
      } else {
        $("#paymentSafe")
          .addClass("fade")
          .modal("show");
      }
    },
    scrollView() {
      this.$refs.continue.scrollIntoView();
    },
    subscribeWithSafe() {
      this.loader1 = true;
      if (this.subscribed === "YES") {
        this.loader1 = false;
        return this.$store.commit("setApiFailed", "You already scubscribed");
      }
      Api.postRequest(`subscriptionwithsafe`, {})
        .then(res => {
          // console.log("res", res);
          this.loader1 = false;
          if (res.data.success) {
            $("#paymentOption")
              .removeClass("fade")
              .modal("hide");
            return this.$store.commit("setApiSuccess", "Success");
          }
          if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.loader1 = false;
        });
    },
    makePayment() {
      // console.log("Payemt");
    },
    // Bank transfer starts
    payFromBank() {
      if (this.subscribed === "YES") {
        return this.$store.commit("setApiFailed", "You already subscribed");
      } else {
        $("#paymentOption")
          .removeClass("fade")
          .modal("hide");
        $("#showpayment")
          .addClass("fade")
          .modal("show");
      }
    },
    switchView(event) {
      if (event === 1) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "block";
        document.getElementById("threee").style.display = "none";
        this.headerText1 = false;
        this.headerText2 = true;
        this.headerText3 = false;
      } else if (event === 2) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "block";
        this.headerText1 = false;
        this.headerText2 = false;
        this.headerText3 = true;
      } else if (event === 0) {
        document.getElementById("onee").style.display = "block";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "none";
        // this.headerText2 = false;
      } else {
        document.getElementById("twoo").style.display = "none";
        document.getElementById("onee").style.display = "none";
        document.getElementById("threee").style.display = "block";
      }
    },

    selectFile() {
      const file = this.$refs.file.files[0];
      this.file = file;
      this.filePath = URL.createObjectURL(file);
      this.fileName = file.name;
      // console.log("fileeeeeeeeeeee", this.file);
      if (this.file) {
        this.fileselected = true;
      } else {
        this.fileselected = false;
      }
    },
    async sendFile() {
      // const file = this.$refs.file.files[0];
      // this.file = file;
      if (!this.file) {
        return this.$store.commit("setApiFailed", "No file was selected!");
      }
      const formData = new FormData();
      formData.append("file", this.file);
      try {
        this.uploading = true;
        this.loading = true;
        await Api.postRequest(`uploadsubscriptionpaymentslip`, formData, {
          onUploadProgress: e =>
            (this.progress = Math.round((e.loaded * 100) / e.total))
        })
          .then(res => {
            this.filenameRes = res.data.filename;
            this.uploading = true;
            this.uploadReceipt();
          })
          .catch(err => {
            this.loading = false;
            this.uploading = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    async uploadReceipt() {
      // this.loading = true;
      try {
        await Api.postRequest("makesubscriptionviatransfer", {
          amount: this.amount,
          filename: this.filenameRes
        })
          .then(res => {
            if (res.data.success) {
              this.switchView(2);
              this.uploading = false;
              this.loading = false;
              this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              this.loading = false;
              this.uploading = false;
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.uploading = false;
            this.loading = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
  // Bank transfer ends
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;
$shelta-black: #000a2f;
$shelta-gray: #555c74;
$shelta-brown: #553b00;
$shelta-warning: #fff7e6;
$shelta-off-white: #f9fafb;
$shelta-grey-2: #57595a;
$dline: #dddddd;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}

@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.top-push {
  margin-top: 7rem;
  // margin-bottom: 5rem;
}

.top_header {
  padding: 24px 0px 16px;

  h4 {
    font-family: Gotham;
    color: $shelta-black;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
  }

  p {
    font-family: Gotham;
    color: $shelta-gray;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
  }
}

.benefits-text {
  text-align: left;
  color: $shelta-black;
  font-family: Gotham-medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.2px;
}

.benefit-wraps {
  text-align: left;

  h6 {
    color: $shelta-black;
    text-align: left;
    font-family: Gotham-medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    display: flex;

    span {
      padding-right: 10px;
    }
  }

  p {
    padding-left: 30px;
    color: $shelta-gray;
    font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.note {
  text-align: left;
  margin-top: 20px;
  padding: 24px 16px;
  background: $shelta-warning;

  h6 {
    color: $shelta-brown;
    font-family: Gotham-bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  ul {
    padding-left: inherit;
  }

  li {
    color: $shelta-brown;
    font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-bottom: 16px;
  }

  li:last-child {
    padding-bottom: 0;
  }
}

.main-footer-button {
  justify-content: space-around !important;
}
.modal-button {
  padding-top: 24px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  border-top: none !important;

  .cancel {
    border-radius: 6px;
    border: 1px solid $dline;
    color: $shelta-black;
    width: 178px;
    height: 48px;
    text-align: center;
    font-family: Gotham;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
  }

  .continue {
    border-radius: 6px;
    background: $primary;
    color: $secondary;
    // width: 178px;
    min-height: 48px;
    text-align: center;
    font-family: Gotham;
    font-size: 16px;
    font-style: normal;
    outline: 0;
    box-shadow: none;
  }
}

.confirmation-header {
  color: $shelta-black;
  text-align: center;
  font-family: Gotham;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.confirmation-body {
  p {
    text-align: left;
    color: $shelta-black;
    font-family: Gotham;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
  }

  span {
    font-weight: bold;
  }
}

// New payment design start
.options {
  button {
    display: block;
    width: 100%;
    text-align: left;
    border-radius: 5px;
    background: $shelta-off-white;
    color: $shelta-grey-2;
    font-family: Gotham;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    padding: 16px;
    padding-left: 35px;
  }
}

// New payment design ends

// Bank transfer upload modal
.payment_header_holder {
  background: $primary;
}

.payment-header {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: $secondary;
}

.close_times {
  color: #ea0000;
  background: $secondary;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0px 6px;
}

.dot-holder {
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
}

.double_arrow_holder {
  p {
    margin-top: 5px;
    color: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
  }
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  // opacity: 1;
  background-color: $primary;
}
.step.finish {
  background-color: #04aa6d;
}

#twoo {
  display: none;
}
#threee {
  display: none;
}

//  First step starts here
.number__holder {
  text-align: left;
  display: flex;
  p:first-child {
    background: $primary;
    // padding: 0.3rem 0.69rem;
    border-radius: 50px;
    color: $secondary;
    text-align: center;
    align-items: baseline;
    margin-right: 16px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.5rem;
    line-height: 11px;
    // width: 2rem;
    // height: 1.5rem;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    color: $black;
    margin-right: 1rem;
  }
}

.sheltaAccount__details {
  background: rgba(64, 123, 255, 0.46);
  margin: 0 -16px 15px -16px;
  // margin-bottom: 15px;
  div {
    display: flex;
    justify-content: space-between;
    margin: 0 3.5rem;
    padding-top: 10px;
    p:first-child {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: $black;
      text-align: left;
      margin-bottom: 0;
    }

    p:nth-child(2) {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: $black;
      text-align: right;
      margin-bottom: 0;
    }
  }
}

.next-btn {
  background: $primary;
  border-radius: 5px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $secondary;
  margin-bottom: 1rem;
  margin-top: 2rem;
  box-shadow: none;
}

.desclaimer__text {
  // padding-top: 4rem;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: $primary;
    text-align: left;
    margin-left: 2.5rem;
  }
}

.modal-foot {
  border-top: none;
}
// Firts modal steps ends

// Secosn modal steo start
.info__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: $black;
  text-align: left;
}

.dateOf__payment {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    color: $black;
  }
  input {
    width: 100%;
    border-bottom: 0.5px solid #6c6c6c !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.upload__file {
  .dropzone {
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 20px 0;
    padding: 50px 5px;
    min-height: 200px;
    cursor: pointer;
    position: relative;
    p {
      margin-bottom: 0;
      padding-top: 10px;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: $black;
    }
    .browse__span {
      color: $primary;
    }
  }
}
.dropzone:hover {
  background-color: lightgrey;
}

// .input-file {
//   opacity: 0;
//   width: 100%;
//   left: 0%;
//   height: 200px;
//   position: absolute;
//   cursor: pointer;
// }
.input-file {
  opacity: 0;
  width: 100%;
  top: 0;
  left: 0%;
  // height: 200px;
  position: absolute;
  height: 100%;
  object-fit: contain;
  z-index: 10;
  cursor: pointer;
}

.actual_image {
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  position: absolute;
  left: 0%;
  cursor: pointer;
}

.third-step {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    // display: flex;
    // align-items: flex-end;
    text-align: center;
    color: $black;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}

.call-btn {
  background: #f2f5fe;
  border: 0.5px solid #6c6c6c;
  box-sizing: border-box;
  border-radius: 10px;

  // width: 154px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  box-shadow: none;
  // display: flex;
  // align-items: flex-end;
  text-align: center;
  color: $black;
  padding: 6px 26px;
  a {
    img {
      width: 27.01px;
      margin-right: 1rem;
    }
  }
}

.back__arrow {
  padding-top: 0.7rem;
  cursor: pointer;
  margin-right: 10px;
}

// Second modal step ends

// Bank transfer modal upload ends

@media only screen and (max-width: 599px) {
  .modal-button {
    display: flex;
    width: auto;
    flex-wrap: nowrap;

    button {
      width: fit-content;
    }
  }

  .top_header {
    h4 {
      font-size: 19px;
    }
  }

  // Bank transfer
  .info__text {
    text-align: justify;
  }
  .number__holder {
    p:nth-child(2) {
      line-height: 23px;
      text-align: left;
      font-size: 13px;
    }
  }
  .sheltaAccount__details {
    div {
      margin: 5px 15px;
      p:first-child {
        font-size: 13px;
      }
      p:nth-child(2) {
        font-size: 13px;
      }
    }
  }
  .second__number {
    padding-top: 2rem;
  }
  .desclaimer__text {
    // padding-top: 1rem;
    p {
      font-size: 13px;
      line-height: 25px;
      text-align: left;
    }
  }
  .payment-header {
    font-size: 15px;
  }
  .third-step {
    p {
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      padding-top: 2rem;
    }
  }
  .call-btn {
    font-size: 13px;
    padding: 6px 34px;
    a {
      img {
        margin-right: 2rem;
        width: 20.01px;
      }
    }
  }
  .third-step {
    img {
      width: 7rem;
    }
  }
  // Bank transfer
}
</style>
